const en = {
    how: "How do you want your egg today?",
    boiledEgg: "Boiled egg",
    softBoiledEgg: "Soft-boiled egg",
    choice: "How to choose the egg",
    enter_both_name_and_password: "Please enter both username and password",
    donot_have_account: "Don't have an account?",
    register: "Register",
    allow_fb_permissions: "Please allow all the facebook permissions to continue.",
    forgot_password: "Forgot password?",
    invalid_credentials: "Invalid credentials",
    something_went_wrong: "Something went wrong!!! Please try again later",
    internal_error: "Internal Error",
    username: "Username",
    password: "Password",
    login: "Login",
    enter_email_to_continue: "Please enter Email to continue",
    enter_valid_email: "Please enter valid email address",
    email_address: "Email Address",
    send_otp: "Send OTP",
    click_here: "Click here",
    back_to_login: "Back to login?",
    edit_email: "Edit email",
    enter_otp_to_continue: "Please enter OTP code to continue",
    enter_otp: "Enter OTP",
    verify: "Verify",
    time_remaining: "Time Remaining:",
    username_exists: "Username exists",
    first_name_required: "First Name Required",
    first_name: "FirstName",
    last_name_required: "Last Name Required",
    last_name: "LastName",
    email: "email",
    password_must_contain: "Password must contain at least 8 characters and at least one Capital Letter",
    already_have_account: "Already have an account?",
    message: "Message",

    //search
    please_subscribe_our_feature: "Please Subscribe our Membership Plan to access this feature and More ..",
    take_me_to_plans: "Take me to the Plans",
    search: "Search",
    attributes: "Attributes",
    usernames: "Username:*",
    enter_username_to_continue: "Please enter username to continue",
    seeking: "Seeking*",
    male: "Male",
    female: "Female",
    couples: "Couples",
    age: "Age:*",
    minAge: "Min Age is 18",
    min_age_Required: "Min age required",
    max_age_Required: "*Max age required",
    provide_valid_range: "Min age required",
    to: "to",
    min_age: "Min Age",
    max_age: "Max Age",
    country: "Country",
    state: "State",
    city: "City",

    //home
    trending_users: "Trending User ",
    meet_this_user: "Want to meet this user?",

    //advanced search
    advanced_search: "Advanced Search",
    updating_profile: "Updating Profile",
    seeking_: "Seeking",
    age_: "Age",

    //chat
    no_friends_to_chat: "No friends to chat.",
    invalid_data: "Invalid Data",

    //accountsettings
    account_settings: "Account Settings",
    update_email: "Update Email",
    change_password: "Change Password",
    please_enter_email_to_continue: "Please enter Email to continue",
    please_enter_valid_email_to_continue: "Please enter valid email address",
    back_to_settings: "Back to Settings?",

    //changePassword
    enter_old_password: "Please enter Old password to continue",
    enter_old_pass: "Enter Old Password",
    enter_new_password_to: "Please enter New password to continue",
    password_validation: "Password must contain at least 8 characters and at least one Capital Letter",
    new_password: "Enter New Password ",

    //verify_email
    back_to_profile: " Back to profile?",

    //pendingFriendRequests
    friend_requests: "Friend Requests",
    no_pending_requests: "You have no Pending friend requests!",

    //respondFriendButton
    please_wait: "Please wait...",
    add_friend: "Add friend",
    cancel_request: "Cancel Request",
    unFriend: "Unfriend",
    send_message: "Send message",
    accept_request: "Accept Request",
    decline: "Decline",

    //sentFriendRequests
    no_pending_request: " You have no Pending Requests",
    pending_requests: "Pending Requests",
    searchFriends: "Search Friends",

    //viewFriends
    all_friends: "All Friends",
    no_friends_yet: "You have no friends yet",

    //userStack
    no_more_members_availabe: "No more members available to meet",

    //chatfeed
    send: "Send",

    //nearMe
    near_me: "Near Me",
    sorry_location_permission_required: "Sorry!! Location Permission required to access this feature....",

    //nearMeSettings
    settings: "Settings",
    units_required: "Units Required",
    units: "Unit:",
    kilometers: "KiloMeters",
    miles: "Miles",
    values_required: "Values Required",
    updating_settings: "Updating Settings",
    save_settings: "Save Settings",

    //notification
    new_message_from: "New message from",
    you_received_message_from: "You have received {} new message from",

    //membershipPlanDetails
    getting_plan_details: "Getting Plan details...",
    payment_successful: "Payment Successful",
    done: "Done",
    home: "Home",
    subscribed: "Subscribed",

    //membershipPlanList
    membership_plans: "Membership Plans",
    membership_lists: "Memberships List",
    sorry_no_membership_plans: "Sorry!! No Membership Plans Found",
    per: "per {}",
    show_features: "Show Features",

    //report user
    report_user: "Report this user",
    enter_reason_for_reporting: "Please Enter Your reason for reporting.",
    please_write_us_reason: "Please write us your reason:",
    report: "Report",
    cancel: "Cancel",

    //otprequest
    username_or_email_associated: "Enter your username or email associated with your account.",
    please_enter_username: "Please enter username to continue",
    username_or_email: "Username or email",

    //otpverify
    edit_username_or_email: "Edit username/email",
    enter_password_to: "Please Enter the Password to continue",
    enter_new_password: " Enter new password",
    reset_password: " Reset Password",

    //searchResults
    search_results: "Search Result",
    no_results_found: "Sorry! No Results found!",


    my_profile: "My Profile",
    friend_lists: "Friends List",
    logout: "Logout",
    logging_out: "Logging Out Please Wait...",
    sure_want_to_logout: "Are you sure want to logout?",
    no: "No",
    yes: "Yes",

    //profile
    profile: "Profile",
    edit_profile: "Edit profile",
    edit_profile_questions: "Edit profile questions",
    no_profile_available: "Sorry! Full Details not Available.",
    show_full_details: "Show Full Details",
    hide_full_details: "Hide Full Details",
    i_am: "I am :",
    seeking_a: "Seeking a :",
    ages: "Age :",
    profile_country: "Country : ",
    profile_state: "State : ",
    profile_city: "City : ",
    my_interests: "My Interests : ",
    please_select_the_image: "Please select the image",
    gender_required: "Gender Required",
    gender: "Gender:",
    dob_required: "Dob required",
    must_be_18: "You must be 18 years",
    birthday: "Birthday:",
    about_me: "About me",
    update: "Update",
    go_back: "Go Back?",
    field_cannot_be_empty: "is required field and cannot be empty",
    is_required_select: "is required field. At least select one.",
    is_required: "is required.",
    getting_profile_question: "Getting profile questions",
    fill_all_required: "Please fill all required field",
    profile_questions: "Profile Questions",
    from: "From",
    forgot: "Forgot?",
    value: "Value"
};
export default en
