import axios from 'axios'
import {apiService} from "../services/apiService";
import appConfig from "../appConfig";

export const utils = {
    getAge,
    addCountryStateCityNameToUsers,
    loadCountryStateCityList,
    loadStateCityList,
    loadCityList,
    getCountryStateCityIds,
    addProfileQuestionsNameToUser,
    getCountryStateCityIndices,
    getDuration,
    getLocalDateFromUTC,
    shouldUpgrade
};

function getAge(birthday) {
    const now = Date.parse(new Date());
    const birthmillis = Date.parse(birthday);

    const divisor = 31557600000;// 1000 * 60 * 60 * 24 * 365.25;

    const age = (now - birthmillis) / divisor;

    return Math.trunc(age);
};

function getCountryStateCityNames(countryId, stateId, cityId) {
    let countryName;
    let stateName;
    let cityName;

    const countries = countrySortedJson.data.country;

    const country = countries[parseInt(countryId) - 1];

    countryName = country.name;
    const states = country.state;

    for (let i = 0; i < states.length; i++) {
        if (parseInt(stateId) === states[i].id) {
            stateName = states[i].name;
            const cities = states[i].city;

            for (let j = 0; j < cities.length; j++) {

                if (parseInt(cityId) === cities[j].id) {
                    cityName = cities[j].name;

                    return {countryName, stateName, cityName};
                }
            }
        }
    }
    return {countryName, stateName, cityName};
};

async function getCountryStateCityIndices(countryId, stateId, cityId) {
    console.log("utils", "the country is before", countryId);
    if (!countryJson) {
        countryJson = await axios.get('../../assets/country.json');
    }
    console.log("utils", "the country after is ", countryId, stateId, cityId);

    const countries = countryJson.data.country;

    for (let i = 0; i < countries.length; i++) {
        if (parseInt(countryId) === countries[i].id) {

            const states = countries[i].state;

            for (let j = 0; j < states.length; j++) {

                if (parseInt(stateId) === states[j].id) {

                    const cities = states[j].city;

                    for (let k = 0; k < cities.length; k++) {

                        if (parseInt(cityId) === cities[k].id) {
                            return new Promise((resolve, reject) => {
                                console.log("dhfdjk", i, j, k, cities[k].id, cityId);
                                resolve({countryIndex: i, stateIndex: j, cityIndex: k});
                            });

                        }
                    }
                }
            }
        }
    }
    console.log("utils", "the country after one  is ", countryId, stateId, cityId);

    return new Promise((resolve, reject) => {
        reject("Not found");
    });
}

let lookUpCache = {};
let countrySortedJson;

async function addCountryStateCityNameToUsers(users, callback) {
    let newUsers = [];
    if (!countrySortedJson) {
        countrySortedJson = await axios.get('../../assets/countrySorted.json');
    }
    console.log('countryJson=', countrySortedJson);
    for (let i = 0; i < users.length; i++) {
        const {
            city_id,
            country_id,
            state_id,
        } = users[i];

        if (parseInt(country_id) === 0) {
            newUsers.push(users[i]);
            continue;
        }

        let cache;
        if (lookUpCache && lookUpCache[country_id] && lookUpCache[country_id][state_id] && (cache = lookUpCache[country_id][state_id][city_id])) {
            const {countryName, stateName, cityName} = cache;
            newUsers.push({...users[i], countryName, stateName, cityName});
            console.log('country, state, city name loaded from cache');
        } else {
            const {countryName, stateName, cityName} = getCountryStateCityNames(country_id, state_id, city_id);

            if (!lookUpCache) {
                lookUpCache = {}
            }

            if (!lookUpCache[country_id]) {
                lookUpCache[country_id] = {}
            }

            if (!lookUpCache[country_id][state_id]) {
                lookUpCache[country_id][state_id] = {};
            }

            if (!lookUpCache[country_id][state_id][city_id]) {
                lookUpCache[country_id][state_id][city_id] = {countryName, stateName, cityName};
            }

            newUsers.push({...users[i], countryName, stateName, cityName});

            console.log('country, state, city name loaded from json');
        }
        console.log('look up cache=', lookUpCache);
    }

    callback(newUsers);
}

let countryJson;

async function loadCountryStateCityList(countryIndex = 0, stateIndex = 0) {
    if (!countryJson) {
        countryJson = await axios.get('../../assets/country.json');
    }

    const countries = countryJson.data.country;
    const states = countries[countryIndex].state;
    const cities = states[stateIndex].city;

    return new Promise((resolve, reject) => {
        resolve({
            countryList: countries.map(country => country.name),
            stateList: states.map(state => state.name),
            cityList: cities.map(city => city.name)
        })
    });
}

async function loadStateCityList(countryIndex) {
    if (!countryJson) {
        countryJson = await axios.get('../../assets/country.json');
    }

    const countries = countryJson.data.country;

    const states = countries[countryIndex].state;

    const cities = states[0].city;

    return new Promise((resolve, reject) => {
        resolve({
            stateList: states.map(state => state.name),
            cityList: cities.map(city => city.name)
        })
    });
}

async function loadCityList(countryIndex, stateIndex) {
    if (!countryJson) {
        countryJson = await axios.get('../../assets/country.json');
    }

    const countries = countryJson.data.country;

    const cities = countries[countryIndex].state[stateIndex].city;

    return new Promise((resolve, reject) => {
        resolve({
            cityList: cities.map(city => city.name)
        })
    });
}

async function getCountryStateCityIds(countryIndex, stateIndex, cityIndex) {
    if (!countryJson) {
        countryJson = await axios.get('../../assets/country.json');
    }

    const countries = countryJson.data.country;

    const country = countries[countryIndex];
    const state = country.state[stateIndex];
    const city = state.city[cityIndex];

    return new Promise((resolve, reject) => {
        resolve({
            countryId: country.id,
            stateId: state.id,
            cityId: city.id
        })
    });
}

async function addProfileQuestionsNameToUser(user) {
    const userProfileQuestions = user.profile_questions;
    if (userProfileQuestions.length === 0) {
        return new Promise((resolve, reject) => {
            resolve({...user, profile_questions: []});
        });
    }
    const existingProfileQuestions = await apiService.getProfileQuestions();
    const idNameMap = {};
    for (let i = 0; i < existingProfileQuestions.data.length; i++) {
        const existingProfileQuestion = existingProfileQuestions.data[i];
        const profileSetupId = existingProfileQuestion.profile_setup_id;
        const questionName = existingProfileQuestion.question_name;
        idNameMap[profileSetupId] = questionName;
    }
    const newUserProfileQuestions = [];
    for (let i = 0; i < userProfileQuestions.length; i++) {
        const userProfileQuestion = userProfileQuestions[i];
        const userProfileQuestionId = userProfileQuestion.profile_question_id;
        const userProfileQuestionName = idNameMap[userProfileQuestionId];
        newUserProfileQuestions.push({...userProfileQuestion, profile_question_name: userProfileQuestionName});
    }
    return new Promise((resolve, reject) => {
        resolve({...user, profile_questions: newUserProfileQuestions});
    })
}

function getDuration(days) {
    let years = Math.trunc(days / 365);
    days = days - (365 * years);
    const months = Math.trunc(days / 30);
    const day = days - (months * 30);

    if (years > 0) {
        if (days % 365 === 0) {

            return (years + " years")
        } else if (days % 30 === 0) {
            return (years + " years " + months + " Months")
        } else if (days % 30 !== 0 && day > 0) {
            return (years + " years " + months + " Months " + day + " days")
        }
    } else if (months > 0) {
        if (days % 30 === 0) {
            return (months + " Months")
        } else if (days % 30 !== 0 && day > 0) {
            return (months + " Months " + day + " days")
        }
    } else if (day > 0 && day < 30) {
        return (day + " days")

    }


}

function getLocalDateFromUTC(date) {
    let dateStr;

    if (date) {
        dateStr = new Date(`${date}Z`.replace(' ', 'T')).toString();
    } else {
        dateStr = new Date().toString();
    }

    const dates = dateStr.split(' ');

    return `${dates[1]}-${dates[2]}-${dates[3]} ${dates[4]}`;
}


async function shouldUpgrade() {

    const newVersion = await apiService.checkVersion();

    const appVersion = appConfig.version;

    console.log('utils.shouldUpgrade.serveResponse=', newVersion);
    console.log('utils.shouldUpgrade.localResponse=', appVersion);

    if (newVersion && appVersion && newVersion.data) {
        const actualVersionCode = newVersion.data.version_code;
        const currentVersionCode = appVersion.version_code;

        console.log('utils.shouldUpgrade.serveResponse.actualVersionCode=', actualVersionCode);
        console.log('utils.shouldUpgrade.localResponse.currentVersionCode=', currentVersionCode);

        if (actualVersionCode && currentVersionCode && parseInt(actualVersionCode) > parseInt(currentVersionCode)) {
            return true;
        }
    }

    return false;
}
